import React from "react";
import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Sonesson Holdings AB" />
    <Image />
    <p className="text-lg text-gray-700 text-center mb-2">
      Nothing to see here.
    </p>
    <footer>
      <p className="text-sm text-gray-700 text-center mt-6">
        © {new Date().getFullYear()} Sonesson Holdings AB
      </p>
    </footer>
  </Layout>
);

export default IndexPage;
